.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-brand {
  max-width: 100%;
  max-height: 100%;
}

.language {
  border: none;
  margin: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  font-family: Segoe UI, sans-serif;
  background: transparent;
  color: #0047ba;
  height: 40px;
  padding: 0 24px;
  font-size: 15px;
  padding-top: 2px;
}

.language:hover {
  text-decoration: underline;
  cursor: pointer;
}

.contactNumber {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #0047ba;
}

.contactNumber > p {
  padding-left: 10px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1.5px;
}
.contactNumber:hover {
  text-decoration: underline;
  cursor: pointer;
}

.headerLinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
