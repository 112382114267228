* {
  margin: 0;
  font-family: Segoe UI, sans-serif;
}

.formWrapper {
  padding: 30px;
  display: grid;
  gap: 2em;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr)); */

  grid-template-areas:
    "a a"
    "b d"
    "b c";
}

@media screen and (max-width: 700px) {
  .formWrapper {
    grid-template-areas:
      "a a"
      "b b"
      "d d"
      "c c";
    padding: 30px 0px;
    justify-content: center;
    justify-items: center;
  }
}

.affiliateImage {
  grid-area: d;

  max-inline-size: 100%;
  block-size: auto;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px;
}

/* @media screen and (max-width: 480px) {
  .formWrapper {
    padding: 30px 0px;
    justify-content: center;
    justify-items: center;
  }
} */
