.amenity > svg {
  width: 32px;
  height: 32px;
}

.amenity {
  display: flex;
  align-items: center;
  gap: 1em;
}

.amenities-wrapper {
  border: 1px solid #d8d8d8;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );
  justify-items: center;
  grid-area: a;
}
