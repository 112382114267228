.floatingContainer {
  position: fixed;
  z-index: 50;
  right: 1.25rem;
  bottom: 1.25rem;
}

.floatingContainer > a > svg {
  width: 64px;
  height: 64px;
}
