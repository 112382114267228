.location {
  width: 95%;
  height: 400px;
  border: 0px;
  border-radius: 10px;
}

.locationContainer > a,.locationContainer > a > a {
  text-decoration: none;
}

.locationContainer > a > a > b {
  font-size: 25px;
  color: #0047ba;
  font-weight: 600;
}

.locationContainer > a > a > b:hover {
  text-decoration: underline;
  cursor: pointer;
}

.locationContainer{
  grid-area: c;
  padding: 0 30px;
}