.carouselContainer {
}

.thumb > img {
  height: 50px !important;
}

.carouselImage {
  height: 600px;
  width: 100vw;
  object-fit: cover;
}

@media screen and (max-width: 480px) {
  .carouselImage {
    height: 350px;
    object-fit: contain;
  }
}
