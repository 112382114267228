.telCode {
  width: 36px;
  pointer-events: none;
}

.telNumber:focus,
.telCode:focus {
  outline: none;
}

.telCode,
.telNumber {
  border: 0;
  font-size: 20px;
  background-color: white !important;
}

.telContainer {
  background: #fff;
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
  padding: 10px;
  border-radius: 1em;
}

.guests {
  width: 40px;
  height: 40px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.guests > svg {
  width: 25px;
  height: 25px;
}

.guestContainer {
  display: flex;
  align-items: center;
  gap: 1em;
}

.sendInquiryBtn {
  margin: 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 16px;
  outline: none;
  padding: 14px;
  background: linear-gradient(90.42deg, #58c5dd 1.83%, #418eb9 99.92%);
  cursor: pointer;
  width: 100%;
}

.sendInquiryBtn:hover {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.08) 0%,
      rgba(0, 0, 0, 0.08) 100%
    ),
    linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      rgb(0, 91, 240);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px 0px inset;
}

.sendInquiryBtn:active {
  transform: translateY(-2px);
}

.inquiryForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 0 50px 4px hsla(0, 0%, 87%, 0.35);
  padding: 24px 24px 30px;
  max-width: 340px;
  background: #042a2b;
  color: white;
  gap: 1em;
  grid-area: b;
}

.guestBlock {
  display: flex;
  align-items: center;
  gap: 1em;
}

* > label > svg {
  width: 32px;
  height: 32px;
}

* > label {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 10px;
}

.mobValidation {
  padding: 0;
  color: red;
  font-style: italic;
}
